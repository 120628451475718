import {
  Box,
  Button,
  Divider,
  Grid,
  Slide,
  Tooltip,
  useTheme,
  Paper
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FileList from "../components/FileList";
import SessionFeedback from "../components/SessionFeedback";
import UserAvatarList from "../components/UserAvatarList";
import { useAnalytics } from "../data/Analytics";
import { DEFAULT_SESSION_IMAGE } from "../data/Configuration";
import { Path } from "../data/Constants";
import useLanguage from "../data/Language";
import { setRoom } from "../data/Store";
import FileService from "../services/FileService";
import UserService from "../services/UserService";
import { styles } from "../styles/SessionViewStyle";
import "../styles/SessionViewStyle.css";
import WindowUtil from "../utils/WindowUtil";

/**
 * Onsite session information view
 * @returns {JSX.Element}
 */
export default function EventSessionOnsiteView(props) {
  const { t } = useTranslation();
  const { value } = useLanguage();
  const analytics = useAnalytics();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = styles();

  useEffect(() => {
    if (!props.session) return;
    if (props.session.room)
      dispatch(setRoom(props.session.room, value(props.session.name)));
    return () => {
      dispatch(setRoom(null, value(props.session.name)));
    };
  }, [props.session]);

  function handleSponsorClick(e, sponsor) {
    e.preventDefault();
    if (sponsor.features && sponsor.features.enablePage)
      history.push(
        Path.EVENT_SPONSOR.replace(":id", props.event.id).replace(
          ":sid",
          sponsor.id
        )
      );
    else WindowUtil.open(sponsor.url);
  }

  const onUserSelect = (user, event) => {
    UserService.viewProfile(dispatch, analytics, user);
  };

  const navigateBeetweenSessions = (e) => {
    e.preventDefault();
    const sessionsId = props.event.sessions.map((session) => session.id);
    const currentIndex = sessionsId.indexOf(props.session.id);
    switch (e.currentTarget.name) {
      case "prev":
        window.location.href = Path.EVENT_SESSION.replace(
          ":id",
          props.event.id
        ).replace(":sid", sessionsId[currentIndex - 1]);
        break;
      case "next":
        window.location.href = Path.EVENT_SESSION.replace(
          ":id",
          props.event.id
        ).replace(":sid", sessionsId[currentIndex + 1]);
        break;
    }
  };

  return (
    <Fragment>
      <Fragment>
        <main className={classes.wrapper}>
          <div className={classes.sessionRoomHead}>
            <div>
              <Typography variant="h3" className={classes.title}>
                {value(props.session.name)}
              </Typography>
            </div>
            <div className={classes.sessionNavBtn}>
              <Button
                name="prev"
                disabled={
                  props.session.id === props.event.sessions[0].id ? true : false
                }
                size="large"
                variant="contained"
                className={classes.button}
                disableElevation
                fullWidth
                onClick={navigateBeetweenSessions}
              >
                <span>{t("client.session.actions.prev-session")}</span>
              </Button>
              <Button
                name="next"
                disabled={
                  props.session.id ===
                  props.event.sessions[props.event.sessions.length - 1].id
                    ? true
                    : false
                }
                size="large"
                variant="contained"
                className={classes.button}
                disableElevation
                fullWidth
                onClick={navigateBeetweenSessions}
              >
                {t("client.session.actions.next-session")}
              </Button>
            </div>
          </div>

          <Paper
            square={true}
            elevation={0}
            className={classes.paperContainer}
            style={{
              backgroundImage:
                "url(" +
                (props.session?.image?.uri 
                  ? FileService.url(props.session.image.uri)
                  : DEFAULT_SESSION_IMAGE) +
                ")",
            }}
          />

          {/* session feedback and speakers */}
          <Box className={classes.areaWrapper}>
            <Box className={classes.area}>
              <SessionFeedback id={props.session.id} />
            </Box>
            {props.session.speakers && props.session.speakers.length > 0 && (
              <Box className={classes.area}>
                <Typography variant="body1" className={classes.label}>
                  {t("client.session.labels.speakers")}
                </Typography>
                <UserAvatarList
                  condensed
                  max={4}
                  users={props.session.speakers}
                  onSelect={onUserSelect}
                />
              </Box>
            )}
          </Box>
          {/* session description, sponsors, files */}
          <Grid
            container
            direction="row"
            style={{ marginTop: theme.spacing(4) }}
          >
            <Grid item xs={12} md={9}>
              <Box>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.description}
                  style={{ paddingRight: 30 }}
                  dangerouslySetInnerHTML={{
                    __html: value(props.session.description),
                  }}
                ></Typography>
              </Box>
              <Box>
                {props.session.sponsors.length > 0 && (
                  <div style={{ marginTop: theme.spacing(4) }}>
                    <Typography
                      variant="h6"
                      component="p"
                      className={classes.label}
                      style={{
                        width: "100%",
                        marginLeft: 0,
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      {t("client.event.labels.sponsored")}
                    </Typography>
                    <div className={classes.sponsorsWrap}>
                      {props.session.sponsors.map(
                        (sponsor, i) =>
                          sponsor.image && (
                            <Box key={"sponsor" + i} className="sponsor">
                              <Tooltip
                                title={value(sponsor.name)}
                                TransitionProps={{ timeout: 0 }}
                                TransitionComponent={Slide}
                              >
                                <a style={{ display: "block" }} href="#">
                                  <img
                                    onClick={(e) =>
                                      handleSponsorClick(e, sponsor)
                                    }
                                    src={
                                      sponsor.image
                                        ? FileService.url(sponsor.image.uri)
                                        : DEFAULT_SESSION_IMAGE
                                    }
                                    alt="Sponsor"
                                    style={{ height: 50 }}
                                  />
                                </a>
                              </Tooltip>
                            </Box>
                          )
                      )}
                    </div>
                  </div>
                )}
              </Box>
            </Grid>
            <Divider />
            {props.session.files && props.session.files.length > 0 && (
              <Grid item xs={12} md={3}>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.label}
                  style={{
                    marginLeft: 0,
                    marginBottom: theme.spacing(2),
                  }}
                >
                  {t("client.session.labels.documents")}
                </Typography>
                <FileList files={props.session.files} />
              </Grid>
            )}
          </Grid>
        </main>
      </Fragment>
    </Fragment>
  );
}

EventSessionOnsiteView.propTypes = {
  event: PropTypes.object,
  session: PropTypes.object,
};

EventSessionOnsiteView.defaultProps = {
  event: null,
  session: null,
};
