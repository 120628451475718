import useEventContext from "../data/EventContext";
import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {Path, SessionType} from "../data/Constants";
import {setSession} from "../data/Store";
import {useHistory, useParams} from "react-router-dom";
import ProgressDialog from "../components/ProgressDialog";
import EventSessionLiveView from "./EventSessionLiveView";
import EventSessionOnsiteView from "./EventSessionOnsiteView";
import EventSessionRoomView from "./EventSessionRoomView";
import EventService from "../services/EventService";
import SessionService from "../services/SessionService";
import {useAuth} from "../components/ProvideAuth";

/**
 * View displaying various session types by delegating to specialized session view types.
 * This view component delegates to two possible child outcomes {@see EventSessionLiveView} & {@see EventSessionRoomView}
 * @author dame.gjorgjievski
 */
export default function EventSessionView() {

    const {id, sid} = useParams()
    const {event, loadEvent} = useEventContext()
    const history = useHistory()
    const dispatch = useDispatch()
    const session = useSelector(state => state.session)
    const [loading, setLoading] = useState(false)
    const auth = useAuth()

    useEffect(() => {
        if (!event) {
            setLoading(true)
            loadEvent(parseInt(id)).then(() => setLoading(false))
            return
        }
        let session = event.sessions.filter(s => s.id === parseInt(sid))[0]
        if (!session) {
            history.push(Path.ROOT)
            return
        }
        dispatch(setSession(session))
        console.log("Session view mount", session)
        return () => {
            dispatch(setSession(null))
            console.log("session view unmount")
        }
    }, [event])

    return <Fragment>
        <ProgressDialog open={loading}/>
        {event && session && session.id > 0  && SessionType.LIVE === session.type &&
                EventService.hasAccess(auth.user, event) && SessionService.hasAccess(auth.user, event, session) &&
            <EventSessionLiveView event={event} session={session} />
        }
        {event && session && session.id > 0  && SessionType.ONSITE === session.type &&
                EventService.hasAccess(auth.user, event) && SessionService.hasAccess(auth.user, event, session) &&
            <EventSessionOnsiteView event={event} session={session} />
        }
        {event && session && session.id > 0  && [SessionType.BREAKOUT, SessionType.NETWORKING].indexOf(session.type) > -1 &&
            EventService.hasAccess(auth.user, event) && SessionService.hasAccess(auth.user, event, session) &&
            <EventSessionRoomView event={event} session={session} />
        }
    </Fragment>
}
