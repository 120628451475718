import React, {Fragment, useEffect, useRef} from 'react'
import Typography from "@material-ui/core/Typography";
import '../styles/SessionViewStyle.css'
import {useAuth} from "../components/ProvideAuth";
import {useTranslation} from "react-i18next";
import useLanguage from "../data/Language";
import PropTypes from "prop-types";
import DailyIframe from "@daily-co/daily-js";
import {createStyles, makeStyles, useTheme} from "@material-ui/core/styles";
import {SessionType} from "../data/Constants";
import {apiFacade} from "../data/ApiFacade";
import {setRoom} from "../data/Store";
import {useDispatch} from "react-redux";
import {Box} from "@material-ui/core";
import SessionFeedback from "../components/SessionFeedback";
import UserAvatarList from "../components/UserAvatarList";
import UserService from "../services/UserService";
import {useAnalytics} from "../data/Analytics";

const styles = makeStyles((theme) => createStyles({
    title: {
        color: theme.palette.tertiary.var2,
        fontWeight: 'bold',
        display: 'inline-box',
        verticalAlign: 'middle'
    },
    description: {
        fontWeight: '400',
        display: 'inline-box',
        verticalAlign: 'middle',
        fontSize:18,
        marginTop:50,
        color: theme.palette.primary.main

    },
    titleWrap: {
        marginBottom: theme.spacing(3)
    },
    wrapper: {
        maxWidth: 1500
    },
    frame: {
        width: '100%',
        height: '100vh',
        maxHeight: '730px'
    },
    titleFlag: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontWeight: 'bold',
        borderRadius: theme.spacing(0.5),
        display: 'inline-box',
        padding: theme.spacing(0.5, 1),
        verticalAlign: 'middle',
        marginLeft: theme.spacing(2)
    },
    area: {
        backgroundColor: theme.palette.common.white,
        borderRadius: 20,
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: theme.spacing(8),
        padding: theme.spacing(0, 1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(0, 2)
        },
        '&:last-child': {
            marginTop: theme.spacing(1),
            [theme.breakpoints.up(1024)]: {
                marginTop: 0
            }
        }
    },
    areaWrapper: {
        display: 'flex',
        marginTop: theme.spacing(2),
        flexDirection: 'column',
        [theme.breakpoints.up(1024)]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        }
    },
    label: {
        display: "inline-block",
        verticalAlign: 'middle',
        margin: '0 10px 0 0',
        fontWeight: 'bold',
        color: theme.palette.tertiary.var2,
        [theme.breakpoints.up('md')]: {
            margin: '0 10px',
        }
    }
}))

/**
 * Session information view displaying a video call room, used for 'breakout' and 'round_table' sessions
 * @param props properties
 * @author dame.gjorgjievski
 */
export default function EventSessionRoomView(props) {

    const {t} = useTranslation()
    const {l, value} = useLanguage()
    const theme = useTheme()
    const auth = useAuth()
    const classes = styles()
    const dispatch = useDispatch()
    const analytics = useAnalytics()
    let container = useRef(null)
    let frame = useRef(null)
    const options = {
        iframeStyle: {
            background: theme.palette.secondary.var2,
            lang: l,
            width: '100%',
            height: '100%',
            border: 'none'
        },
        userName: auth.user.name + " " + auth.user.surname,
        showLeaveButton: false,
        showFullscreenButton: true,
        subscribeToTracksAutomatically: true,
        showLocalVideo: true,
        showParticipantsBar: false
    }

    useEffect(() => {
        if (!props.session) return
        apiFacade.frame = DailyIframe.createFrame(container.current, options)
        frame.current = apiFacade.frame
        if(props.session.room) dispatch(setRoom(props.session.room))
        console.log("Session room view mount", frame.current)
        return () => {
            apiFacade.frame = null
            dispatch(setRoom(null))
            console.log("Session room view unmount")
        }
    }, [props.session])

    const onUserSelect = (user, event) => {
        UserService.viewProfile(dispatch, analytics, user)
    }

    return (
        <Fragment>
            <Fragment>
                <main className={classes.wrapper}>
                    <div className={classes.titleWrap}>
                        <Typography variant="h3" className={classes.title}>
                            {value(props.session.name)}
                        </Typography>
                        <Typography variant="button" className={classes.titleFlag}>
                            {SessionType.BREAKOUT === props.session.type ?
                                t('client.session.labels.breakout-session') : t('client.session.labels.round-table-session')}</Typography>
                    </div>
                    <div ref={container} className={classes.frame}/>
                    {/* session feedback and speakers */}
                    <Box className={classes.areaWrapper}>
                        {props.session.speakers && props.session.speakers.length > 0 &&
                        <Box className={classes.area}>
                            <Typography variant="body1"
                                        className={classes.label}>{t('client.session.labels.speakers')}</Typography>
                            <UserAvatarList condensed max={4} users={props.session.speakers} onSelect={onUserSelect}/>
                        </Box>}
                    </Box>
                    <Typography variant="h3" className={classes.description} dangerouslySetInnerHTML={{__html: value(props.session.description)}}/>
                </main>
            </Fragment>
        </Fragment>
    )
}

EventSessionRoomView.propTypes = {
    event: PropTypes.object,
    session: PropTypes.object
};

EventSessionRoomView.defaultProps = {
    event: null,
    session: null
}