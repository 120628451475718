import axios from "axios"
import {API_URL, PUBLIC_API_URL} from "../data/Configuration"

/**
 * Service for public API data access
 * @author dame.gjorgjievski
 */
const PublicService = {
    /**
     * Loads the public information about an event
     * @param id event identifier
     * @returns {Promise<AxiosResponse<any>>}
     */
    loadEvent: async function (id) {
        return axios.get(PUBLIC_API_URL + "events/" + id)
    },

    loadEventByName: async function (name) {
        return axios.get(API_URL + "events/event-name/" + name)
    }
}
export default PublicService