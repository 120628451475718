import React, { useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import Button from '@material-ui/core/Button'
import { Box, ButtonGroup, CssBaseline, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { styles } from "../styles/EventIntroViewStyle"
import Alert from '@material-ui/lab/Alert'
import ProgressDialog from "../components/ProgressDialog";
import { DEFAULT_EVENT_INTRO_IMAGE, OIDC_AUTHORITY, OIDC_CLIENT_ID } from "../data/Configuration";
import { Path } from "../data/Constants";
import FileService from "../services/FileService";
import { useTranslation } from "react-i18next";
import useLanguage from "../data/Language";
import clsx from 'clsx';
import PublicService from "../services/PublicService";
import DateUtil from "../utils/DateUtil";
import { useAnalytics } from "../data/Analytics";

/**
 * Event intro view
 * @author petar.todorovski
 */
export default function EventIntroView(props) {

    const { t } = useTranslation()
    const { lang, setLang, value } = useLanguage()
    const classes = styles()
    const history = useHistory()
    const theme = useTheme()
    const [loading, setLoading] = useState(true)
    const [event, setEvent] = useState(null)
    const [state, setState] = useState({
        username: "",
        password: "",
        code: null
    })
    let [errorz, setErrorz] = useState({
        username: "",
        password: "",
        code: ""
    })
    const [errors, setErrors] = useState([])
    const { eventNameOrId } = useParams()
    const isLaptop = useMediaQuery(theme.breakpoints.up('md'));
    const analytics = useAnalytics()

    const hasValidUser = () => !!localStorage.getItem("user") && !!sessionStorage.getItem(`oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`)

    useEffect(() => {
        const id = parseInt(eventNameOrId)
        isNaN(id)
            ? PublicService.loadEventByName(eventNameOrId).then(response => {
                setEvent(response.data)
                setLoading(false)
            }).catch(error => {
                setLoading(false)
            })
            : PublicService.loadEvent(parseInt(id)).then(response => {
                setEvent(response.data)
                setLoading(false)
            }).catch(error => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        if (event == null) return
        if (event.id && hasValidUser()) setTimeout(() => {
            history.push(Path.EVENT_LOBBY.replace(":id", event.id))
        }, 7000)
    }, [event])

    const submit = (e) => {
        /*e.preventDefault()
        errorz = {...errorz, username: requiredValidation("Username", state.username)};
        if (errorz.username.length === 0)
            errorz = {...errorz, username: emailValidation(state.username)};
        errorz = {...errorz, password: requiredValidation("Password", state.password)};
        if (state.code !== null)
            errorz = {...errorz, code: requiredValidation("Code", state.code)};
        setErrorz(errorz)
        if (errorz.username.length > 0 || errorz.password.length > 0 || errorz.code.length > 0) return;

        if (state.code == null) accessCode()
        else login(state.username, state.password, state.code)*/

        history.push(Path.ROOT)
    }

    return <div className={classes.landingRoot}>
        <CssBaseline />
        <ProgressDialog open={loading} />
        {event && <main className={classes.landingWrap}>
            {/* Header */}
            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.headWrap}>
                <Box display="flex" alignItems="center" className={classes.logoWrap}>
                    {event.logo ?
                        <img alt="Event Logo" src={FileService.url(event.logo.uri)} />
                        : <>
                            <Typography variant="h6" className="logo-left">{t('client.menu.event')}</Typography>
                            <Typography variant="subtitle1" className="logo-right">{t('client.menu.room')}</Typography>
                        </>}
                </Box>
                {/* eve t languages */}
                {event.languages &&
                    <ButtonGroup className={classes.languageSwitch} size="small" variant="text" color="primary"
                        aria-label="event-languages">
                        {event.languages.map((lng, i) =>
                            <Button key={'lng' + i} className={clsx('lng', lng === lang() ? 'lng-active' : '')}
                                onClick={(e) => setLang(lng)}>{lng.toUpperCase()}</Button>
                        )}
                    </ButtonGroup>}
            </Box>
            <Grid container spacing={isLaptop ? 10 : 4} className={classes.landingGrid}>
                {/* Info grid item */}
                <Grid item md={6} className="grid-item left">
                    <Box className={classes.leftHalfInner}>
                        <Box className={classes.textSection}>
                            <Typography variant="h2">
                                {t('client.event.labels.welcome')}
                            </Typography>
                            <Typography variant="h1" className="top-text">{value(event.name)}</Typography>
                            <Typography variant="h3" className="date-text">{DateUtil.difference(
                                DateUtil.fromUtc(new Date(event.timeStart)), DateUtil.fromUtc(new Date(event.timeEnd)))
                            }</Typography>
                            <Typography variant="body1" className="bottom-text" dangerouslySetInnerHTML={{ __html: value(event.description) }} />
                        </Box>

                        {/* Login Form */}
                        {!(hasValidUser()) && <Box>
                            <form className={classes.loginForm}>
                                <Box display="flex" alignItems="center">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        className={classes.loginBtn}
                                        onClick={submit}
                                        size="large"
                                        disableElevation
                                    >{t('client.user.actions.login')}</Button>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body2" component="span">{t('client.user.labels.no-login')}</Typography>
                                        {event && event.contact &&
                                            <Button href={`mailto:${event.contact}`} className={classes.signUp}>
                                                <Typography className="sign-up-text" variant="button"
                                                    component="span">{t('client.event.labels.contact')}</Typography>
                                            </Button>}
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="left" className={classes.errors}>
                                    {errors.length > 0 && <Alert severity="error">
                                        {errors.map((msg, i) =>
                                            <div key={'err' + i}>{msg}</div>
                                        )}
                                    </Alert>}
                                </Box>
                            </form>
                        </Box>}

                        {/** Display redirect information */}
                        {hasValidUser() && <Box className={classes.leftHalfInner}>
                            <Box className={classes.textSection}>
                                <Typography variant="body1"
                                    className="bottom-text">{t('client.event.labels.thank-you-attendee')}</Typography>
                                <Typography variant="body1"
                                    className="bottom-text">{t('client.event.labels.thank-you-attendee-redirect-manually')}&nbsp;
                                    <Link to={Path.EVENT_LOBBY.replace(":id", event.id)} >{t("admin.default.fields.event")}</Link>
                                </Typography>
                            </Box>
                        </Box>}
                    </Box>
                </Grid>

                {/* image item grid */}
                <Grid item md={6} className="grid-item right">
                    <Box className={classes.rightHalfInner} display="flex" justifyContent="flex-end">
                        <Box className="image-wrap">
                            <img alt="Event Intro" style={{ width: '100%' }}
                                src={event.image ? FileService.url(event.image.uri) : DEFAULT_EVENT_INTRO_IMAGE} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </main>}
    </div>
}
