import {createStyles, makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => createStyles({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.var1,
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
    formLabel: {
        width: '100%',
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    input: {
        margin: theme.spacing(0)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        flexGrow: 1
    },
    helperText: {
        marginLeft: theme.spacing(2)
    },
    checkBoxList: {
        maxHeight: '200px',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    descriptionToolbar: {
        backgroundColor: 'rgb(245 245 245)',
        borderBottom: '1px solid rgb(221, 221, 221)'
    },
    descriptionWrapper: {
        border: '1px solid rgb(221, 221, 221)'
    },
    label: {
        marginLeft: theme.spacing(2),
        backgroundColor: 'rgb(25 118 210);',
    }
}));