import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AddBox, Edit, MailOutline, MailSharp, NotificationImportant } from "@material-ui/icons";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Alert from "@material-ui/lab/Alert";
import { convertToHTML } from 'draft-convert';
import { ContentState, EditorState, convertFromHTML, convertToRaw, convertFromRaw } from 'draft-js';
import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { EventMail, Path } from "../data/Constants";
import useLanguage from "../data/Language";
import EventService from "../services/EventService";
import { tableIcons } from "../styles/TableIcons";
import CompUtil from "../utils/CompUtil";
import StringUtil from "../utils/StringUtil";
import DateUtil from "../utils/DateUtil";

const styles = makeStyles((theme) => createStyles({
    messageBody: {
        height: '450px',
        '&::-webkit-scrollbar': {
            width: 0
        },
    },
    input: {
        marginBottom: '10px'
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
    editor: {
        border: '1px solid lightgray',
        '&:hover': {
            border: '1px solid lightgray'
        }
    }
}))

/**
 * The events listing component, lists all incoming events for a participant user
 * @returns {JSX.Element}
 * @constructor
 */
export default function EventsListView() {
    const eventHeader = `EventName: Personal access data`;
    const eventMessage = `  Dear online participant,
  The Course starts on Month Date, Year and we’re looking forward to welcome you on our virtual event platform.
   
  To participate, please log in with the following credentials:
  Link to the Platform: :baseURL/event/2/intro
  User: :email
  Password: 
   
  Your profile is non-transferable and was created especially for you. To access the course, you will need to verify your email address first.
  If you have any technical problems, please contact platform@newsroom.tech and we will get back to you as fast as possible.
  After the end of the course, the content will be available on the platform until -/-/-. Just use your login information to access it all over again.
   
  See you soon.`;

    const [dialogState, setDialogState] = useState({ isOpen: false, dialogHeader: '' });
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(ContentState.createFromText(eventMessage))
    );
    const [subject, setSubject] = useState(eventHeader);
    const classes = styles()
    const { value } = useLanguage()
    const { t } = useTranslation()
    const columns = [
        { title: t("admin.default.fields.id"), field: "id", hidden: true, defaultSort: "desc" },
        { title: t("admin.default.fields.name"), field: `name`, render: rowData => value(rowData.name, null, 30) },
        { title: t("admin.event.fields.access"), field: "access" },
        {
            title: t("admin.default.fields.time-start"), field: "timeStart", render: rowData => {
                return DateUtil.toString(DateUtil.fromUtc(new Date(rowData.timeStart))).replace("T", " ")
            }
        },
        {
            title: t("admin.default.fields.time-end"), field: "timeEnd", render: rowData => {
                return DateUtil.toString(DateUtil.fromUtc(new Date(rowData.timeEnd))).replace("T", " ")
            }
        },
        { title: t("admin.default.fields.groups"), field: "", render: rowData => rowData.groups.length },
        { title: t("admin.event.fields.sessions"), field: "", render: rowData => rowData.sessions.length },
        { title: t("admin.default.fields.sponsors"), field: "", render: rowData => rowData.sponsors.length }
    ]
    const [errors, setErrors] = useState([])
    const history = useHistory()

    const handleRowDelete = (oldData, resolve) => {
        EventService.delete(oldData.id)
            .then(res => {
                resolve({ page: 0 })
            })
            .catch(error => {
                setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
                clearErrors()
                resolve()
            })
    }

    const clearErrors = () => {
        setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    const handleMessageSend = () => {
        const msgBody = convertToHTML(editorState.getCurrentContent());
        const msgJson = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

        EventService.sendMail(dialogState.eventId, EventMail.REGISTRATION, subject, msgBody);
        EventService.saveMessageBody(dialogState.eventId, JSON.stringify(subject), msgJson, msgBody).then(result => {
            setDialogState({ ...dialogState, isOpen: false });
        });
    }

    const handleSaveDraftMessage = () => {
        const msgBody = convertToHTML(editorState.getCurrentContent());
        const msgJson = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

        EventService.saveMessageBody(dialogState.eventId, JSON.stringify(subject), msgJson, msgBody).then(result => {
            setDialogState({ ...dialogState, isOpen: false });
        });
    }

    const handleDialogClose = () => {
        setSubject(eventHeader);
        setDialogState({ ...dialogState, isOpen: false });
    };

    return (
        <Container maxWidth="lg" style={{ width: '100%' }}>
            <MaterialTable
                title={t('admin.event.title')}
                columns={columns}
                icons={tableIcons}
                data={query => {
                    return new Promise((resolve, reject) => {
                        EventService.search(query)
                            .then(response => {
                                resolve({
                                    data: response.data.result,
                                    page: query.page,
                                    totalCount: response.data.totalCount,
                                })
                            })
                            .catch(error => {
                                console.log("got error", error, error.response)
                                setErrors([t("admin.default.errors.data-load")])
                            })
                    })
                }}
                actions={[
                    {
                        icon: () => <MailOutline />,
                        tooltip: t("admin.event.actions.send-invitation"),
                        onClick: (event, rowData) => {
                            if (window.confirm(t("admin.event.messages.send-invitation")
                                .replace("{event}", value(rowData.name)))) {
                                EventService.sendMail(rowData.id, EventMail.INVITATION)
                            }
                        }
                    },
                    {
                        icon: () => <MailSharp />,
                        tooltip: t("admin.event.actions.send-registration"),
                        onClick: (event, rowData) => {
                            const header = t("admin.event.messages.send-registration")
                                .replace("{event}", value(rowData.name));
                            EventService.getMessageBody(rowData.id).then(result => {
                                const subject = JSON.parse(result.data.subject)
                                const body = JSON.parse(result.data.message)
                                setSubject(subject)
                                setEditorState(EditorState.createWithContent(convertFromRaw(body)));
                                setDialogState({ ...dialogState, eventId: rowData.id, isOpen: true, dialogHeader: header });
                            }).catch(() => {
                                console.log('The message is absent, take the default one')
                                setEditorState(EditorState.createWithContent(ContentState.createFromText(eventMessage)));
                                setDialogState({ ...dialogState, eventId: rowData.id, isOpen: true, dialogHeader: header });
                            })
                        }
                    },
                    {
                        icon: () => <NotificationImportant />,
                        tooltip: t("admin.event.actions.send-notification"),
                        onClick: (event, rowData) => {
                            let message = window.prompt(t("admin.event.messages.send-notification")
                                .replace("{event}", value(rowData.name)))
                            if (!StringUtil.isEmpty(message)) EventService.sendNotification(rowData.id, message)
                        }
                    },
                    {
                        icon: () => <VisibilityIcon />,
                        tooltip: t("admin.default.actions.view"),
                        onClick: (event, rowData) => {
                            history.push(Path.EVENT_INTRO.replace(":eventNameOrId", rowData.shortName || rowData.id))
                        }
                    },
                    {
                        icon: () => <AddBox data-cy="add-event-btn" />,
                        tooltip: t("admin.default.actions.add"),
                        position: "toolbar",
                        onClick: () => {
                            history.push(Path.ADMIN_EVENT_EDIT.replace(":id", "0"))
                        }
                    },
                    {
                        icon: () => <Edit />,
                        tooltip: t("admin.default.actions.edit"),
                        onClick: (event, rowData) => {
                            history.push(Path.ADMIN_EVENT_EDIT.replace(":id", rowData.id))
                        }
                    }
                ]}
                editable={{
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleRowDelete(oldData, resolve)
                        })
                }}
                options={{
                    actionsColumnIndex: -1,
                }}
                localization={CompUtil.localizeTable(t)}
            />
            <div style={{ height: '30px' }} />
            <div>
                {errors.length > 0 &&
                    <Alert severity="error">
                        {errors.map((msg, i) => {
                            return <div key={i}>{msg}</div>
                        })}
                    </Alert>
                }
            </div>
            <div>
                <Dialog
                    onClose={handleDialogClose}
                    aria-labelledby="registration-title"
                    open={dialogState.isOpen}
                    fullWidth
                    maxWidth={'md'}                >
                    <DialogTitle id="registration-title">
                        {dialogState.dialogHeader}
                    </DialogTitle>
                    <DialogContent dividers className={classes.messageBody}>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField className={classes.input}
                                variant="outlined"
                                margin="none"
                                fullWidth
                                required
                                id="subject"
                                label={t('admin.default.fields.subject')}
                                name="subject"
                                autoComplete="subject"
                                autoFocus
                                value={subject}
                                onChange={e => setSubject(e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <div className={classes.editor}>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                />
                            </div>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleSaveDraftMessage}>
                            Save Template 
                        </Button>
                        <Button autoFocus onClick={handleDialogClose}>
                            Cancel
                        </Button>
                        <Button autoFocus onClick={handleMessageSend}>
                            Send Message
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Container>
    )
}
