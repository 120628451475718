import { Box, Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CountDownTimer from "../components/CountDownTimer";
import FileList from "../components/FileList";
import ProgressDialog from "../components/ProgressDialog";
import { useAuth } from "../components/ProvideAuth";
import SessionCard from "../components/SessionCard";
import SessionHeadline from "../components/SessionHeadline";
import SessionTab from "../components/SessionTab";
import UserAvatarList from "../components/UserAvatarList";
import { useAnalytics } from "../data/Analytics";
import { DEFAULT_EVENT_IMAGE } from "../data/Configuration";
import { Path, SessionType } from "../data/Constants";
import useEventContext from "../data/EventContext";
import useLanguage from "../data/Language";
import { setRoom } from "../data/Store";
import EventService from "../services/EventService";
import FileService from "../services/FileService";
import SessionService from "../services/SessionService";
import UserService from "../services/UserService";
import { styles } from "../styles/EventLobbyViewStyles";
import { theme } from "../styles/Theme";
import DateUtil from "../utils/DateUtil";
import WindowUtil from "../utils/WindowUtil";

/**
 * Event information lobby view
 * @returns {JSX.Element}
 * @author petar.todorovski
 * @author dame.gjorgjievski
 */
export default function EventLobbyView() {
  const { t } = useTranslation();
  const { value } = useLanguage();
  const classes = styles();
  const dispatch = useDispatch();
  const auth = useAuth();
  const [index, setIndex] = useState(0);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [headlines, setHeadlines] = useState([]);
  const { event, loadEvent } = useEventContext();
  const history = useHistory();
  const { id } = useParams();
  const analytics = useAnalytics();

  useEffect(() => {
    if (!event) setLoading(true);
    loadEvent(parseInt(id)).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (event == null) return;
    if (event.id !== parseInt(id)) return; // skip for non current event
    let sessions = getSessions();
    let dates = DateUtil.extrapolate(
      sessions.map((session) => DateUtil.fromUtc(new Date(session.timeStart)))
    );
    setDates(dates);
    setIndex(0);
    showSessions(dates[0]);
    extractSpeakers(sessions);
    extractHeadlines(sessions);
    if (event.room) dispatch(setRoom(event.room));
    console.log("lobby view mount", event);
  }, [event]);

  useLayoutEffect(
    () => () => {
      dispatch(setRoom(null));
      console.log("Lobby view unmount");
    },
    []
  );

  const getSessions = () =>
    (event.sessions || [])
      .filter((s) => SessionService.hasAccess(auth.user, event, s))
      .filter(
        (s) =>
          new Date(s.timeEnd) > DateUtil.utc() &&
          SessionType.NETWORKING !== s.type
      );

  const onDateChange = (event, value) => {
    setIndex(value);
    showSessions(dates[value]);
  };

  const showSessions = (date) => {
    setSessions(
      getSessions().filter((s) => {
        let ss = DateUtil.fromUtc(new Date(s.timeStart)).getTime(),
          ts = date.getTime(),
          te = ts + 86400000;
        return ts <= ss && ss < te;
      })
    );
  };

  const extractSpeakers = (sessions) => {
    let speakers = [];
    let ids = [];
    getSessions().forEach((s) => {
      s.speakers.forEach((sp) => {
        if (ids.indexOf(sp.id) < 0) {
          speakers.push(sp);
          ids.push(sp.id);
        }
      });
    });
    setSpeakers(speakers);
  };

  const extractHeadlines = (sessions) => {
    let live = [],
      incoming = [],
      passed = [];
    getSessions().forEach((s) => {
      let ts = new Date(s.timeStart),
        te = new Date(s.timeEnd),
        now = DateUtil.utc();
      if (ts < now && now < te) live.push(s);
      else if (now < ts && now < te) incoming.push(s);
      else passed.push(s);
    });
    live.sort((a, b) => new Date(a.timeStart) - new Date(b.timeStart));
    incoming.sort((a, b) => new Date(a.timeStart) - new Date(b.timeStart));
    passed.sort((a, b) => new Date(a.timeStart) - new Date(b.timeStart));
    let headlines = [...live, ...incoming, ...passed];
    // console.log("headlines", headlines)
    setHeadlines(headlines.slice(0, 2));
  };

  const getArrowComponent = (props) => {
    switch (props.direction) {
      case "left":
        return (
          <IconButton {...props} disableRipple style={{ borderRadius: 0 }}>
            <ArrowBackIosIcon
              style={{ marginLeft: "7px", color: theme.palette.primary.var3 }}
              fontSize="large"
              icon="arrow-left"
            />
          </IconButton>
        );
      case "right":
        return (
          <IconButton {...props} disableRipple style={{ borderRadius: 0 }}>
            <ArrowBackIosIcon
              style={{
                marginLeft: "7px",
                transform: "rotate(180deg)",
                color: theme.palette.primary.var3,
              }}
              fontSize="large"
              icon="arrow-right"
            />
          </IconButton>
        );
      default:
        return null;
    }
  };

  const onUserSelect = (user, event) => {
    UserService.viewProfile(dispatch, analytics, user);
  };

  function handleSponsorClick(e, v) {
    const sponsor = event.sponsors[v];
    e.preventDefault();
    console.log("sponsor select", sponsor);
    if (sponsor.features && sponsor.features.enablePage)
      history.push(
        Path.EVENT_SPONSOR.replace(":id", id).replace(":sid", sponsor.id)
      );
    else WindowUtil.open(sponsor.url);
  }

  return (
    <Fragment>
      <ProgressDialog open={loading} />
      {event && EventService.hasAccess(auth.user, event) && (
        <main className={classes.lobbyWrap}>
          <Paper
            square={true}
            elevation={0}
            className={classes.paperContainer}
            style={{
              backgroundImage:
                "url(" +
                (event.media
                  ? FileService.url(event.media.uri)
                  : DEFAULT_EVENT_IMAGE) +
                ")",
            }}
          >
            <Grid container className={classes.heroGridContainer}>
              <Grid item xs={12} lg={6} className="left-grid-item">
                <Typography variant="h2">
                  {t("client.event.labels.welcome")}
                </Typography>
                <Typography variant="h1">{value(event.name)}</Typography>
                <Typography variant="subtitle1" className="date-text">
                  {DateUtil.difference(
                    DateUtil.fromUtc(new Date(event.timeStart)),
                    DateUtil.fromUtc(new Date(event.timeEnd))
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6} className="right-grid-item">
                <div style={{ minHeight: 110 }}>
                  {DateUtil.utc() < new Date(event.timeStart) && (
                    <div className={classes.timer}>
                      <Box
                        display="flex"
                        alignItems="center"
                        className="timer-title-wrap"
                      >
                        <Typography variant="subtitle1" className="timer-text">
                          {t("client.event.labels.starts")}
                        </Typography>
                        <Box className="border-line-right" component="span" />
                      </Box>
                      <CountDownTimer
                        className
                        time={DateUtil.fromUtc(
                          new Date(event.timeStart)
                        ).getTime()}
                      />
                    </div>
                  )}
                </div>
                {speakers && speakers.length > 0 && (
                  <div className={classes.speakersWrap}>
                    <Typography variant="subtitle1">
                      {t("client.event.labels.speakers")}
                    </Typography>
                    <UserAvatarList
                      large
                      condensed
                      onSelect={onUserSelect}
                      max={4}
                      users={speakers}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
          {event.sponsors.filter((s) => s.features.showInLobby).length > 0 && (
            <Box className={classes.sponsorsMainWrap}>
              <Typography gutterBottom className="title-by" variant="body1">
                {t("client.event.labels.sponsored")}
              </Typography>
              <Tabs
                id="sponsor-tabs"
                value={0}
                onChange={handleSponsorClick}
                className={classes.sponsorTabs}
                orientation="horizontal"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                TabIndicatorProps={{ style: { display: "none" } }}
                ScrollButtonComponent={(props) => getArrowComponent(props)}
              >
                {event.sponsors
                  .filter((s) => s.features.showInLobby)
                  .map((sponsor, i) => (
                    <Tab
                      key={"sponsor-tab-" + i}
                      icon={
                        <img
                          src={
                            sponsor.image
                              ? FileService.url(sponsor.image.uri)
                              : ""
                          }
                          style={{ height: 50, marginTop: 10 }}
                          alt="Sponsor Logo"
                        />
                      }
                    />
                  ))}
              </Tabs>
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item xs={8} md={9} lg={10}>
              <Typography
                variant="h5"
                component="p"
                className={classes.textSection}
                dangerouslySetInnerHTML={{ __html: value(event.description) }}
              ></Typography>
            </Grid>

            {event.files && event.files.length > 0 && (
              <Grid item xs={4} md={3} lg={2}>
                <Box className={classes.infoBottomItem}>
                  <Typography className="doc-title" variant="h5" gutterBottom>
                    {t("client.event.labels.documents")}
                  </Typography>
                  <FileList files={event.files} />
                </Box>
              </Grid>
            )}
          </Grid>

          {/* upcoming and live sessions boxes */}
          {headlines.length > 0 && (
            <Grid container spacing={3}>
              {headlines.map((headline, i) => (
                <SessionHeadline
                  onSelect={onUserSelect}
                  event={event}
                  session={headline}
                  key={"headline" + i}
                />
              ))}
            </Grid>
          )}

          {sessions.length > 0 && (
            <Fragment>
              <Paper
                square={true}
                elevation={0}
                className={classes.papersSessions}
              >
                <div className={classes.paperTabs}>
                  <Typography variant="h3">
                    {t("client.event.labels.sessions")}
                  </Typography>
                  <Tabs
                    id="sessions-tabs"
                    value={index}
                    onChange={onDateChange}
                    className={classes.dateTabs}
                    orientation="horizontal"
                    variant="scrollable"
                    indicatorColor="secondary"
                    textColor="primary"
                    TabIndicatorProps={{
                      style: {
                        height: "4px",
                        backgroundColor: theme.palette.secondary.main,
                      },
                    }}
                  >
                    {dates.map((date, i) => (
                      <Tab
                        key={"session-tab-" + i}
                        label={<SessionTab date={date} />}
                      ></Tab>
                    ))}
                  </Tabs>
                </div>
                <Grid
                  container
                  spacing={3}
                  className={classes.paperSessionsInner}
                >
                  {sessions.map((session, i) => (
                    <Grid item xs={12} key={i}>
                      <SessionCard session={session} event={event} />
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Fragment>
          )}
          {/** event && event.room && <ChatBar room={event.room}/> **/}
        </main>
      )}
    </Fragment>
  );
}
