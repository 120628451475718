import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import AddBoxOutlined from "@material-ui/icons/AddBoxOutlined";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { DefaultEditor } from "react-simple-wysiwyg";
import EditInLanguageSelector from "../components/EditInLanguageSelector";
import FileUpload from "../components/FileUpload";
import ProgressDialog from "../components/ProgressDialog";
import { Language, Path, SessionType } from "../data/Constants";
import EventService from "../services/EventService";
import SessionService from "../services/SessionService";
import SponsorService from "../services/SponsorService";
import UserService from "../services/UserService";
import { styles } from "../styles/FormStyles";
import DateUtil from "../utils/DateUtil";
import FuncUtil from "../utils/FuncUtil";
import StringUtil from "../utils/StringUtil";
import {
  requiredTranslation,
  requiredValidation,
  sizeValidation,
} from "../utils/Validators";

/**
 * Displays an event session edit view
 * @author dame.gjorgjievski
 */
export default function SessionEditView() {
  const DEFAULT_URL = { [Language.DEFAULT]: "https://youtube.com/" };
  const DEFAULT_MAX_USERS = 0;
  const MAX_USERS_LOW = 8;
  const MAX_USERS_HIGH = 200;
  const MIN_USERS = 2;
  const DEFAULT_STATE = {
    name: { [Language.DEFAULT]: "New Session" },
    description: { [Language.DEFAULT]: "New Session Description" },
    url: DEFAULT_URL,
    type: SessionType.LIVE,
    timeStart: DateUtil.toString(DateUtil.utc()),
    timeEnd: DateUtil.toString(DateUtil.utc()),
    event: { id: 0 },
    image: null,
    speakers: [],
    users: [],
    features: {
      enableChat: true,
      enableQa: true,
      enablePoll: true,
      enableWordCloud: true,
      selectedUsers: false,
      maxUsers: DEFAULT_MAX_USERS,
    },
    files: [],
    groups: [],
    sponsors: [],
  };
  const { t } = useTranslation();
  const classes = styles();
  const history = useHistory();
  const theme = useTheme();
  const [state, setState] = useState(DEFAULT_STATE);
  let [errorz, setErrorz] = useState({
    name: "",
    url: "",
    type: "",
    description: "",
    event: "",
    timeStart: "",
    timeEnd: "",
    maxUsers: "",
  });
  const [language, setLanguage] = useState(Language.current());
  const [speakers, setSpeakers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [events, setEvents] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const { id } = useParams();
  const timeout = useRef(0);
  const loaded = useRef(false);

  useEffect(() => {
    setLoading(parseInt(id) > 0);
    SessionService.load(id)
      .then((result) => {
        setState(result.data);
        setLoading(false);
      })
      .catch((e) => setState({ ...state }));
    return () => clearInterval(timeout.current);
  }, []);

  useEffect(() => {
    if (state == DEFAULT_STATE) return;
    if (events.length === 0)
      EventService.find(0, 5000).then((result) => {
        setEvents(result.data);
        let event = result.data.find((e) => e.id === state.event.id);
        if (event) setGroups(event.groups || []);
      });
    if (sponsors.length === 0)
      SponsorService.find(0, 5000).then((result) => {
        setSponsors(result.data);
      });
  }, [state]);

  const submit = (e) => {
    e.preventDefault();
    // validate and populate errors
    errorz = {
      ...errorz,
      name: requiredTranslation("Session name", state.name),
    };
    errorz = {
      ...errorz,
      type: requiredValidation("Session type", state.type),
    };
    if (state.event.id === 0)
      errorz = { ...errorz, event: "Please select event" };
    // errorz = {...errorz, description: requiredTranslation("Session description", state.description)}
    // type specific validation
    let props = ["name", "type", "event"];
    switch (state.type) {
      case SessionType.LIVE:
      case SessionType.EXTERNAL:
        props = [...props, "url", "timeStart", "timeEnd"];
        errorz = {
          ...errorz,
          url: requiredTranslation("Session url", state.url),
        };
        errorz = {
          ...errorz,
          timeStart: requiredValidation("Session start date", state.timeStart),
        };
        errorz = {
          ...errorz,
          timeEnd: requiredValidation("Session end date", state.timeEnd),
        };
        break;
      case SessionType.BREAKOUT:
        props = [...props, "timeStart", "timeEnd"];
        errorz = {
          ...errorz,
          timeStart: requiredValidation("Session start date", state.timeStart),
        };
        errorz = {
          ...errorz,
          timeEnd: requiredValidation("Session end date", state.timeEnd),
        };
        if (state.features && state.features.maxUsers !== DEFAULT_MAX_USERS)
          errorz = {
            ...errorz,
            maxUsers: sizeValidation(
              "Max users",
              state.features.maxUsers,
              MIN_USERS,
              MAX_USERS_HIGH
            ),
          };
        if (StringUtil.isEmpty(state.url[Language.DEFAULT]))
          state.url = DEFAULT_URL;
        break;
      case SessionType.NETWORKING:
        props = [...props, "maxUsers"];
        if (StringUtil.isEmpty(state.url[Language.DEFAULT]))
          state.url = DEFAULT_URL;
        errorz = {
          ...errorz,
          maxUsers: sizeValidation(
            "Max users",
            state.features.maxUsers,
            MIN_USERS,
            MAX_USERS_LOW
          ),
        };
        state.timeStart = state.event.timeStart;
        state.timeEnd = state.event.timeEnd;
        break;
    }
    if (!state.description[Language.DEFAULT])
      state.description[Language.DEFAULT] = "";
    setErrorz(errorz);
    clearErrors();
    for (let i in props) if (!StringUtil.isEmpty(errorz[props[i]])) return;

    // time specific validation
    let timeStart = Date.parse(state.timeStart),
      timeEnd = Date.parse(state.timeEnd);
    let etimeStart = Date.parse(state.event.timeStart),
      etimeEnd = Date.parse(state.event.timeEnd);
    if (timeStart > timeEnd) {
      setErrors(["Start date must be before end date"]);
      clearErrors();
      return;
    }
    // if (timeStart < now) {
    //     setErrors(["Session start date must be in the future"])
    //     clearErrors()
    //     return
    // }
    if (etimeStart > timeStart) {
      setErrors(["Session start date must be after event start date"]);
      clearErrors();
      return;
    }
    if (timeEnd > etimeEnd) {
      setErrors(["Session end date must be before event end date"]);
      clearErrors();
      return;
    }
    setLoading(true);
    SessionService.save(state)
      .then((result) => {
        setLoading(false);
        history.push(Path.ADMIN_SESSIONS);
      })
      .catch((error) => {
        setLoading(false);
        setErrors(
          error.response.data.errors.length > 0
            ? error.response.data.errors
            : [error.response.data.message]
        );
        clearErrors();
      });
  };

  const handleChange = (field, e, value) => {
    let val = null;
    switch (field) {
      case "name":
        const name = { ...state.name, [language]: e.target.value };
        setState({ ...state, name });
        setErrorz({ ...errorz, [field]: requiredTranslation("Name", name) });
        break;
      case "type":
        setState({ ...state, [field]: e.target.value });
        setErrorz({
          ...errorz,
          [field]: requiredValidation("Type", e.target.value),
        });
        break;
      case "event":
        let event = events.find((ev) => ev.id === e.target.value) || { id: 0 };
        setGroups(event.groups || []);
        setState({ ...state, [field]: event });
        setErrorz({
          ...errorz,
          [field]: event.id === 0 ? "Please select event" : "",
        });
        break;
      case "description":
        const description = {
          ...state.description,
          [language]: e.target.value,
        };
        setState({ ...state, description });
        // setErrorz({...errorz, [field]: requiredTranslation("Description", description)})
        break;
      case "url":
        const url = { ...state.url, [language]: e.target.value };
        setState({ ...state, url });
        setErrorz({ ...errorz, [field]: requiredTranslation("URL", url) });
        break;
      case "timeStart":
        val = DateUtil.toString(DateUtil.toUtc(e));
        setState({ ...state, [field]: val });
        setErrorz({
          ...errorz,
          [field]: requiredValidation("Start Date", val),
        });
        break;
      case "timeEnd":
        val = DateUtil.toString(DateUtil.toUtc(e));
        setState({ ...state, [field]: val });
        setErrorz({ ...errorz, [field]: requiredValidation("End Date", val) });
        break;
      case "enableChat":
      case "enableQa":
      case "enablePoll":
      case "enableWordCloud":
      case "selectedUsers":
        setState({
          ...state,
          features: { ...state.features, [field]: e.target.checked },
        });
        break;
      case "maxUsers":
        setState({
          ...state,
          features: { ...state.features, [field]: parseInt(e.target.value) },
        });
        if (
          SessionType.NETWORKING === state.type ||
          (SessionType.BREAKOUT === state.type &&
            state.features.maxUsers !== DEFAULT_MAX_USERS)
        )
          setErrorz({
            ...errorz,
            [field]: sizeValidation(
              "Max Users",
              e.target.value,
              2,
              SessionType.NETWORKING === state.type
                ? MAX_USERS_LOW
                : MAX_USERS_HIGH
            ),
          });
        break;
      case "sponsors":
        setState({ ...state, [field]: value });
        break;
      default:
        break;
    }
  };

  const onImageComplete = (response) => {
    setState({ ...state, image: response.data });
  };

  const onRemoveImage = (file) => {
    setState({ ...state, image: null });
  };

  const onFileComplete = (response) => {
    let files = state.files;
    if (files == null) return;
    files.push(response.data);
    setState({ ...state, files: files });
  };

  const onRemoveFile = (file) => {
    let files = state.files;
    if (files == null) return;
    let idx = files.indexOf(file);
    if (idx > -1) files.splice(idx, 1);
    setState({ ...state, files: files });
  };

  const clearErrors = () => {
    timeout.current = setTimeout(() => {
      setErrors([]);
    }, 3000);
  };

  const querySpeakers = (query) => () => {
    UserService.query(query).then((response) => {
      setSpeakers(response.data);
    });
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <ProgressDialog open={loading} />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AddBoxOutlined />
        </Avatar>
        <Grid
          spacing={2}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Typography component="h1" variant="h5">
              {t("admin.session.title-edit")}
            </Typography>
          </Grid>
          <Grid item>
            <EditInLanguageSelector
              language={language}
              onChange={(e) => setLanguage(e.target.value)}
            />
          </Grid>
        </Grid>
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  id="name"
                  label={
                    t("admin.default.fields.session") + " (" + language + ")"
                  }
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={state.name[language] || ""}
                  onChange={(e) => handleChange("name", e)}
                  error={errorz.name !== ""}
                  helperText={errorz.name}
                />
              </FormControl>
              <FormControl
                fullWidth
                error={errorz.event !== ""}
                className={classes.formControl}
              >
                <InputLabel
                  id="event-select-label"
                  className={classes.formLabel}
                >
                  Event *
                </InputLabel>
                <Select
                  labelId="event-select-label"
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="event"
                  label={t("admin.default.fields.event")}
                  name="event"
                  autoComplete="event"
                  value={state.event.id}
                  onChange={(e) => handleChange("event", e)}
                  error={errorz.event !== ""}
                >
                  <MenuItem value="0">
                    <em>{t("admin.default.labels.none")}</em>
                  </MenuItem>
                  {events.map((e, index) => (
                    <MenuItem key={index} value={e.id}>
                      {e.name[language]
                        ? e.name[language]
                        : e.name[Language.DEFAULT]}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={errorz.event !== ""}
                  className={classes.helperText}
                >
                  {errorz.event}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                error={errorz.type !== ""}
                className={classes.formControl}
              >
                <InputLabel
                  id="type-select-label"
                  className={classes.formLabel}
                >
                  Type *
                </InputLabel>
                <Select
                  labelId="type-select-label"
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="type"
                  label={t("admin.default.fields.type")}
                  name="type"
                  autoComplete="type"
                  value={state.type}
                  onChange={(e) => handleChange("type", e)}
                  error={errorz.type !== ""}
                >
                  <MenuItem value="">
                    <em>{t("admin.default.labels.none")}</em>
                  </MenuItem>
                  <MenuItem value={SessionType.LIVE}>
                    {t("data.session-type.live")}
                  </MenuItem>
                  <MenuItem value={SessionType.BREAKOUT}>
                    {t("data.session-type.breakout")}
                  </MenuItem>
                  <MenuItem value={SessionType.NETWORKING}>
                    {t("data.session-type.round-table")}
                  </MenuItem>
                  <MenuItem value={SessionType.EXTERNAL}>
                    {t("data.session-type.external")}
                  </MenuItem>
                  <MenuItem value={SessionType.ONSITE}>
                    {t("data.session-type.onsite")}
                  </MenuItem>
                </Select>
                <FormHelperText className={classes.helperText}>
                  {errorz.type}
                </FormHelperText>
              </FormControl>
              {[SessionType.LIVE, SessionType.EXTERNAL].indexOf(state.type) >
                -1 && (
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="none"
                    fullWidth
                    required
                    id="url"
                    label={
                      t("admin.default.fields.url") + " (" + language + ")"
                    }
                    name="url"
                    autoComplete="url"
                    value={state.url[language] || ""}
                    onChange={(e) => handleChange("url", e)}
                    error={errorz.url !== ""}
                    helperText={errorz.url}
                  />
                </FormControl>
              )}
              {SessionType.NETWORKING !== state.type && (
                <FormControl
                  fullWidth
                  error={errorz.timeStart !== ""}
                  className={classes.formControl}
                >
                  <KeyboardDateTimePicker
                    id="timeStart"
                    value={DateUtil.toString(
                      DateUtil.fromUtc(new Date(state.timeStart))
                    )}
                    onChange={(e) => handleChange("timeStart", e)}
                    inputVariant="outlined"
                    format={DateUtil.FORMAT}
                    label={t("admin.default.fields.time-start")}
                    error={errorz.timeStart !== ""}
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormHelperText className={classes.helperText}>
                    {errorz.timeStart}
                  </FormHelperText>
                </FormControl>
              )}
              {SessionType.NETWORKING !== state.type && (
                <FormControl
                  fullWidth
                  error={errorz.timeEnd !== ""}
                  className={classes.formControl}
                >
                  <KeyboardDateTimePicker
                    id="timeEnd"
                    value={DateUtil.toString(
                      DateUtil.fromUtc(new Date(state.timeEnd))
                    )}
                    onChange={(e) => handleChange("timeEnd", e)}
                    inputVariant="outlined"
                    format={DateUtil.FORMAT}
                    label={t("admin.default.fields.time-end")}
                    error={errorz.timeEnd !== ""}
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormHelperText className={classes.helperText}>
                    {errorz.timeEnd}
                  </FormHelperText>
                </FormControl>
              )}
              <FormControl fullWidth className={classes.formControl}>
                <FormLabel component="legend" className={classes.formLabel}>
                  {t("admin.default.fields.description")}
                </FormLabel>
                <DefaultEditor
                  value={state.description[language] || ""}
                  onChange={(e) => handleChange("description", e)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ padding: theme.spacing(2) }}>
              <FormControl
                fullWidth
                className={classes.formControl}
                component="fieldset"
              >
                <FormLabel component="legend" className={classes.formLabel}>
                  {t("admin.default.fields.features")}
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enableChat"
                        checked={state.features.enableChat}
                        onChange={(e) => handleChange("enableChat", e)}
                      />
                    }
                    label={t("admin.session.fields.feature-chat")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enableQa"
                        checked={state.features.enableQa}
                        onChange={(e) => handleChange("enableQa", e)}
                      />
                    }
                    label={t("admin.session.fields.feature-qa")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enableWordCloud"
                        checked={state.features.enableWordCloud}
                        onChange={(e) => handleChange("enableWordCloud", e)}
                      />
                    }
                    label={t("admin.session.fields.feature-word-cloud")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enablePoll"
                        checked={state.features.enablePoll}
                        onChange={(e) => handleChange("enablePoll", e)}
                      />
                    }
                    label={t("admin.session.fields.feature-poll")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="selectedUsers"
                        disabled={SessionType.NETWORKING === state.type}
                        checked={state.features.selectedUsers}
                        onChange={(e) => handleChange("selectedUsers", e)}
                      />
                    }
                    label={t("admin.session.fields.feature-selected-users")}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="none"
                    style={{
                      display: "inline-block",
                      marginRight: 0,
                      width: "20%",
                    }}
                    type="number"
                    min={0}
                    InputProps={{
                      inputProps: {
                        min: 2,
                        max:
                          SessionType.NETWORKING === state.type
                            ? MAX_USERS_LOW
                            : MAX_USERS_HIGH,
                      },
                    }}
                    required
                    id="maxUsers"
                    label={t("admin.session.fields.feature-max-users")}
                    name="maxUsers"
                    autoComplete="maxUsers"
                    value={state.features.maxUsers}
                    onChange={(e) => handleChange("maxUsers", e)}
                    error={errorz.maxUsers !== ""}
                  />
                </FormGroup>
              </FormControl>
              {state.features.selectedUsers && (
                <>
                  <FormControl fullWidth className={classes.formControl}>
                    <Autocomplete
                      value={state.groups}
                      multiple
                      options={groups}
                      filterOptions={(x) => x}
                      onChange={(e, value) => {
                        console.log("groups", value, [
                          ...value.map((g) => (g.id ? g.id : g)),
                        ]);
                        setState({
                          ...state,
                          groups: [...value.map((g) => (g.id ? g.id : g))],
                        });
                      }}
                      getOptionLabel={(option) =>
                        (groups.find((g) => g.id === option) || {}).name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("admin.default.fields.groups")}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 4 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      getOptionSelected={(option, value) => {
                        return (
                          state.groups.find((g) => g === option.id) != null
                        );
                      }}
                    />
                  </FormControl>
                </>
              )}
              <FormControl fullWidth className={classes.formControl}>
                <Autocomplete
                  value={state.speakers}
                  multiple
                  autoComplete
                  options={speakers}
                  filterOptions={(x) => x}
                  onInputChange={(event, value) => {
                    FuncUtil.debounce(querySpeakers, 2000)(value);
                  }}
                  onChange={(e, value) => {
                    setState({ ...state, speakers: [...value] });
                  }}
                  getOptionLabel={(option) =>
                    option.name + " " + option.surname
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("admin.session.fields.speakers")}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 4 }}
                        checked={selected}
                      />
                      {option.name +
                        " " +
                        option.surname +
                        " (" +
                        option.email +
                        ")"}
                    </React.Fragment>
                  )}
                  getOptionSelected={(option, value) => {
                    return (
                      state.speakers.filter((u) => u.id === option.id)[0] !=
                      null
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Autocomplete
                  multiple
                  freeSolo
                  options={sponsors}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    option.name[language]
                      ? option.name[language]
                      : option.name[Language.EN]
                  }
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={state.sponsors}
                  onChange={(e, value) => handleChange("sponsors", e, value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={
                          option.name[language]
                            ? option.name[language]
                            : option.name[Language.EN]
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("admin.default.fields.sponsors")}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <FileUpload
                  onComplete={onImageComplete}
                  files={state.image ? [state.image] : []}
                  update
                  label={t("admin.session.fields.image")}
                  accept={".jpg, .jpeg, .gif, .png"}
                  onRemove={onRemoveImage}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <FileUpload
                  onComplete={onFileComplete}
                  files={state.files}
                  label={t("admin.default.fields.files")}
                  accept={
                    ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .odx, .txt"
                  }
                  onRemove={onRemoveFile}
                />
              </FormControl>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ width: "50%" }}
                  className={classes.submit}
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                  }}
                >
                  {t("admin.default.actions.cancel")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: "50%" }}
                  className={classes.submit}
                  onClick={submit}
                >
                  {t("admin.default.actions.submit")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ height: "30px" }} />
      <div>
        {errors.length > 0 && (
          <Alert severity="error">
            {errors.map((msg, i) => {
              return <div key={i}>{msg}</div>;
            })}
          </Alert>
        )}
      </div>
    </Container>
  );
}
