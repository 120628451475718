import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AddBoxOutlined from "@material-ui/icons/AddBoxOutlined";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { styles } from "../styles/FormStyles";
import {
  emailValidation,
  requiredTranslation,
  requiredValidation,
} from "../utils/Validators";
import Alert from "@material-ui/lab/Alert";
import EventService from "../services/EventService";
import { Autocomplete } from "@material-ui/lab";
import FileUpload from "../components/FileUpload";
import DateUtil from "../utils/DateUtil";
import SponsorService from "../services/SponsorService";
import ProgressDialog from "../components/ProgressDialog";
import { Access, Language, Languages, Path } from "../data/Constants";
import { useTranslation } from "react-i18next";
import EditInLanguageSelector from "../components/EditInLanguageSelector";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import StringUtil from "../utils/StringUtil";
import useEventContext from "../data/EventContext";
import { DefaultEditor } from "react-simple-wysiwyg";
import Box from "@material-ui/core/Box";

/**
 * Displays an event edit view
 * @author dame.gjorgjievski
 */
export default function EventEditView() {
  const { t } = useTranslation();
  const classes = styles();
  const history = useHistory();
  const [state, setState] = useState({
    name: {
      [Language.DEFAULT]: "New Event",
    },
    shortName: null,
    description: {
      [Language.DEFAULT]: "New Event Description",
    },
    access: Access.PRIVATE,
    timeStart: DateUtil.toString(DateUtil.utc()),
    timeEnd: DateUtil.toString(DateUtil.utc()),
    users: [],
    media: null,
    image: null,
    logo: null,
    files: [],
    contact: null,
    sponsors: [],
    features: {
      showAttendeeEmail: true,
    },
    languages: [Language.DEFAULT],
    eventPas: "",
  });
  let [errorz, setErrorz] = useState({
    name: "",
    description: "",
    access: "",
    timeStart: "",
    timeEnd: "",
    contact: "",
    eventPas: ""
  });
  const [language, setLanguage] = useState(Language.current());
  const [errors, setErrors] = useState([]);
  const [users, setUsers] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { loadEvents } = useEventContext();
  let timeout = useRef(0);

  useEffect(() => {
    if (parseInt(id) > 0) {
      setLoading(parseInt(id) > 0);
      EventService.load(id).then((result) => {
        setState(result.data);
        SponsorService.find(0, 5000).then((result) => {
          setSponsors(result.data);
          setLoading(false);
        });
      });
    }
    return () => clearInterval(timeout.current);
  }, []);

  const submit = (e) => {
    e.preventDefault();
    errorz = { ...errorz, name: requiredTranslation("Event name", state.name) };
    errorz = {
      ...errorz,
      access: requiredValidation("Event access", state.access),
    };
    errorz = {
      ...errorz,
      description: requiredTranslation("Event description", state.description),
    };
    errorz = {
      ...errorz,
      timeStart: requiredValidation("Event start date", state.timeStart),
    };
    errorz = {
      ...errorz,
      timeEnd: requiredValidation("Event end date", state.timeEnd),
    };
    errorz = {
      ...errorz,
      eventPas: requiredValidation("Event password", state.eventPas),
    };
    if (!StringUtil.isEmpty(state.contact))
      errorz = { ...errorz, contact: emailValidation(state.contact) };
    else errorz = { ...errorz, contact: "" };
    setErrorz(errorz);
    clearErrors();
    let props = [
      "name",
      "type",
      "access",
      "description",
      "timeStart",
      "timeEnd",
      "contact",
      "eventPas"
    ];
    for (let i in props) {
      if (errorz[props[i]] && errorz[props[i]].length > 0) return;
    }
    let timeStart = Date.parse(state.timeStart),
      timeEnd = Date.parse(state.timeEnd),
      now = DateUtil.utc();
    if (timeStart > timeEnd) {
      setErrors(["Start date must be before end date"]);
      clearErrors();
      return;
    }
    // if (timeStart < now) {
    //     setErrors(["Event start date must be in the future"])
    //     clearErrors()
    //     return
    // }
    // if (state.access === Access.PUBLIC && state.users.length > 0) state.users = []
    setLoading(true);
    EventService.save(state)
      .then((result) => {
        setLoading(false);
        history.push(Path.ADMIN_EVENTS);
        loadEvents();
      })
      .catch((error) => {
        setLoading(false);
        console.log("submit event error", error.response);
        setErrors(
          error.response.data.errors.length > 0
            ? error.response.data.errors
            : [error.response.data.message]
        );
        clearErrors();
      });
  };

  const handleChange = (field, e, value) => {
    let val = null;
    switch (field) {
      case "name":
        const name = { ...state.name, [language]: e.target.value };
        setState({ ...state, name });
        setErrorz({ ...errorz, name: requiredTranslation("Name", name) });
        break;
      case "shortName":
        setState({ ...state, [field]: e.target.value });
        break;
      case "description":
        const description = {
          ...state.description,
          [language]: e.target.value,
        };
        setState({ ...state, description });
        setErrorz({
          ...errorz,
          description: requiredTranslation("Description", description),
        });
        break;
      case "access":
        setState({ ...state, access: e.target.value });
        setErrorz({
          ...errorz,
          access: requiredValidation("Access", e.target.value),
        });
        break;
      case "contact":
        setState({ ...state, [field]: e.target.value });
        if (!StringUtil.isEmpty(e.target.value))
          setErrorz({ ...errorz, [field]: emailValidation(e.target.value) });
        else setErrorz({ ...errorz, [field]: "" });
        break;
      case "timeStart":
        val = DateUtil.toString(DateUtil.toUtc(e));
        console.log(
          "timeStart",
          e,
          val,
          DateUtil.toString(DateUtil.fromUtc(new Date(val)))
        );
        setState({ ...state, timeStart: val });
        setErrorz({
          ...errorz,
          timeStart: requiredValidation("Start Date", val),
        });
        break;
      case "timeEnd":
        val = DateUtil.toString(DateUtil.toUtc(e));
        console.log(
          "timeEnd",
          e,
          val,
          DateUtil.toString(DateUtil.fromUtc(new Date(val)))
        );
        setState({ ...state, timeEnd: val });
        setErrorz({ ...errorz, timeEnd: requiredValidation("End Date", val) });
        break;
      case "sponsors":
        setState({ ...state, sponsors: value });
        break;
      case "languages":
        const langs = new Set(state.languages || []);
        e.target.checked
          ? langs.add(e.target.name)
          : langs.delete(e.target.name);
        setState({ ...state, languages: [...langs] });
        break;
      case "showAttendeeEmail":
        setState({
          ...state,
          features: { ...state.features, [field]: e.target.checked },
        });
        break;
      case "eventPas":
        setState({ ...state, [field]: e.target.value });
        setErrorz({
          ...errorz,
          eventPas: requiredValidation("Event password", e.target.value),
        });
        break;
      default:
        break;
    }
  };

  const onUploadComplete = (field) => (response) => {
    switch (field) {
      case "media":
      case "image":
      case "video":
      case "logo":
        setState({ ...state, [field]: response.data });
        break;
      case "files":
        let files = state[field];
        if (files == null) return;
        files.push(response.data);
        setState({ ...state, [field]: files });
        break;
      default:
        break;
    }
  };

  const onRemoveFile = (field) => (file) => {
    switch (field) {
      case "media":
      case "image":
      case "video":
        setState({ ...state, [field]: null });
        break;
      case "files":
        let files = state[field];
        if (files == null) return;
        let idx = files.indexOf(file);
        if (idx > -1) files.splice(idx, 1);
        setState({ ...state, [field]: files });
        break;
      default:
        break;
    }
  };

  const clearErrors = () => {
    timeout.current = setTimeout(() => {
      setErrors([]);
    }, 3000);
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <ProgressDialog open={loading} />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AddBoxOutlined />
        </Avatar>
        <Grid
          spacing={2}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Typography component="h1" variant="h5">
              {t("admin.event.title-edit")}
            </Typography>
          </Grid>
          <Grid item>
            <EditInLanguageSelector
              language={language}
              onChange={(e) => setLanguage(e.target.value)}
            />
          </Grid>
        </Grid>
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  id="name"
                  label={t("admin.default.fields.name") + " (" + language + ")"}
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={state.name[language] || ""}
                  onChange={(e) => handleChange("name", e)}
                  error={errorz.name !== ""}
                  helperText={errorz.name}
                  data-cy="event-name-text-field"
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  variant="outlined"
                  margin="none"
                  fullWidth
                  id="shortName"
                  label={t("admin.default.fields.shortName")}
                  name="shortName"
                  autoComplete="short name"
                  autoFocus
                  value={state.shortName || ""}
                  onChange={(e) => handleChange("shortName", e)}
                  error={errorz.name !== ""}
                  helperText={errorz.name}
                  data-cy="event-name-text-field"
                />
              </FormControl>
              <FormControl
                fullWidth
                error={errorz.access !== ""}
                className={classes.formControl}
              >
                <InputLabel
                  id="access-select-label"
                  className={classes.formLabel}
                >
                  {t("admin.event.fields.access")} *
                </InputLabel>
                <Select
                  labelId="access-select-label"
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="access"
                  label={t("admin.event.fields.access")}
                  name="access"
                  autoComplete="access"
                  value={state.access}
                  onChange={(e) => handleChange("access", e)}
                  error={errorz.access !== ""}
                >
                  <MenuItem value="">
                    <em>{t("data.access-type.none")}</em>
                  </MenuItem>
                  <MenuItem value={Access.PRIVATE}>
                    {t("data.access-type.private")}
                  </MenuItem>
                  <MenuItem value={Access.PUBLIC}>
                    {t("data.access-type.public")}
                  </MenuItem>
                </Select>
                <FormHelperText className={classes.helperText}>
                  {errorz.access}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                error={errorz.timeStart !== ""}
                className={classes.formControl}
              >
                <KeyboardDateTimePicker
                  id="timeStart"
                  value={DateUtil.fromUtc(new Date(state.timeStart))}
                  onChange={(e) => handleChange("timeStart", e)}
                  inputVariant="outlined"
                  format={DateUtil.FORMAT}
                  label={t("admin.default.fields.time-start")}
                  error={errorz.timeStart !== ""}
                  onError={console.log}
                  InputLabelProps={{ shrink: true }}
                />
                <FormHelperText className={classes.helperText}>
                  {errorz.timeStart}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                error={errorz.timeEnd !== ""}
                className={classes.formControl}
              >
                <KeyboardDateTimePicker
                  id="timeEnd"
                  value={DateUtil.fromUtc(new Date(state.timeEnd))}
                  onChange={(e) => handleChange("timeEnd", e)}
                  inputVariant="outlined"
                  format={DateUtil.FORMAT}
                  label={t("admin.default.fields.time-end")}
                  error={errorz.timeEnd !== ""}
                  onError={console.log}
                  InputLabelProps={{ shrink: true }}
                />
                <FormHelperText className={classes.helperText}>
                  {errorz.timeEnd}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <FormLabel component="legend" className={classes.formLabel}>
                  {t("admin.default.fields.description")}
                </FormLabel>
                <DefaultEditor
                  value={state.description[language] || ""}
                  onChange={(e) => handleChange("description", e)}
                />
                <FormHelperText className={classes.helperText} error={true}>
                  {errorz.description}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  id="contact"
                  label={t("admin.event.fields.contact")}
                  name="contact"
                  autoComplete="contact"
                  autoFocus
                  value={state.contact || ""}
                  onChange={(e) => handleChange("contact", e)}
                  error={errorz.contact !== ""}
                  helperText={errorz.contact}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  variant="outlined"
                  margin="none"
                  fullWidth
                  required
                  id="eventPas"
                  label={t("admin.default.fields.event-pas")}
                  error={errorz.eventPas !== ""}
                  helperText={errorz.eventPas}
                  name="eventPas"
                  autoComplete="eventPas"
                  autoFocus
                  value={state.eventPas || ""}
                  onChange={(e) => handleChange("eventPas", e)}
                  data-cy="event-name-text-field"
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="sponsors-filled"
                  freeSolo
                  options={sponsors}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    option.name[language]
                      ? option.name[language]
                      : option.name[Language.EN]
                  }
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={state.sponsors}
                  onChange={(e, value) => handleChange("sponsors", e, value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={
                          option.name[language]
                            ? option.name[language]
                            : option.name[Language.EN]
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("admin.default.fields.sponsors")}
                    />
                  )}
                  renderOption={(props, option) => {
                    const { id, name, notes, ...optionProps } = props;
                    return (
                      <Box
                        key={id}
                        component="li"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > .name": { mr: 2 },
                        }}
                        {...optionProps}
                      >
                        <span className="name">
                          {name?.[language]
                            ? name[language]
                            : name?.[Language.EN] || "Unknown"}
                        </span>
                        {notes && (
                          <Chip
                            className={classes.label}
                            // color="info"
                            size="small"
                            label={notes}
                            // sx={{ ml: 2 }}
                          />
                        )}
                      </Box>
                    );
                  }}
                />
              </FormControl>
              <FormControl
                fullWidth
                className={classes.formControl}
                component="fieldset"
              >
                <FormLabel component="legend" className={classes.formLabel}>
                  {t("admin.event.fields.languages")}
                </FormLabel>
                <FormGroup row>
                  {Languages.map((l, i) => (
                    <FormControlLabel
                      key={"lang" + i}
                      control={
                        <Checkbox
                          name={l.code}
                          disabled={
                            state.languages.length === 1 &&
                            state.languages.indexOf(l.code) > -1
                          }
                          checked={state.languages.indexOf(l.code) > -1}
                          onChange={(e) => handleChange("languages", e)}
                        />
                      }
                      label={t("data.languages." + l.code)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <FormControl
                fullWidth
                className={classes.formControl}
                component="fieldset"
              >
                <FormLabel component="legend" className={classes.formLabel}>
                  {t("admin.default.fields.features")}
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="showAttendeeEmail"
                        checked={state.features.showAttendeeEmail}
                        onChange={(e) => handleChange("showAttendeeEmail", e)}
                      />
                    }
                    label={t("admin.event.fields.feature-showAttendeeEmail")}
                  />
                </FormGroup>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <FileUpload
                  onComplete={onUploadComplete("logo")}
                  files={state.logo ? [state.logo] : []}
                  update
                  label={t("admin.default.fields.logo")}
                  accept={".jpg, .jpeg, .gif, .png"}
                  onRemove={onRemoveFile("logo")}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <FileUpload
                  onComplete={onUploadComplete("image")}
                  files={state.image ? [state.image] : []}
                  update
                  label={t("admin.default.fields.image")}
                  accept={".jpg, .jpeg, .gif, .png"}
                  onRemove={onRemoveFile("image")}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <FileUpload
                  onComplete={onUploadComplete("media")}
                  files={state.media ? [state.media] : []}
                  update
                  label={t("admin.default.fields.media")}
                  accept={".jpg, .jpeg, .gif, .png, .avi, .mp4, .mov"}
                  onRemove={onRemoveFile("media")}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <FileUpload
                  onComplete={onUploadComplete("files")}
                  files={state.files}
                  label={t("admin.default.fields.files")}
                  accept={
                    ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .odx, .txt"
                  }
                  onRemove={onRemoveFile("files")}
                />
              </FormControl>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                  }}
                >
                  {t("admin.default.actions.cancel")}
                </Button>
                {parseInt(id) !== 0 && (
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.submit}
                    onClick={submit}
                  >
                    {t("admin.default.actions.cache-clear")}
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={submit}
                  data-cy="submit-event-btn"
                >
                  {t("admin.default.actions.submit")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ height: "30px" }} />
      <div>
        {errors.length > 0 && (
          <Alert severity="error">
            {errors.map((msg, i) => {
              return <div key={i}>{msg}</div>;
            })}
          </Alert>
        )}
      </div>
    </Container>
  );
}
