import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import DateUtil from "../utils/DateUtil";
import { DEFAULT_SESSION_IMAGE } from "../data/Configuration";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { theme } from "../styles/Theme";
import { useHistory } from "react-router-dom";
import UserAvatarList from "../components/UserAvatarList";
import { SessionStatusFlag } from "./SessionStatus";
import { Path, SessionType } from "../data/Constants";
import FileService from "../services/FileService";
import { useTranslation } from "react-i18next";
import useLanguage from "../data/Language";
import WindowUtil from "../utils/WindowUtil";
import { useAnalytics } from "../data/Analytics";
import { useDispatch } from "react-redux";
import UserService from "../services/UserService";

const styles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      height: "100%",
      minHeight: "230px",
      padding: theme.spacing(0.6),
      backgroundColor: "transparent",
      flexDirection: "column",
      [theme.breakpoints.up(1120)]: {
        flexDirection: "row",
        padding: (props) =>
          props.xs === 6
            ? theme.spacing(0, 0.6, 0, 6.25)
            : theme.spacing(0, 6.25, 0, 0.6),
        marginBottom: "10px",
      },
      [theme.breakpoints.up(1350)]: {
        minHeight: "280px",
      },
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: (props) => (props.xs === 6 ? "80%" : "100%"),
      left: (props) => (props.xs === 6 ? "80px" : "0px"),
      position: "relative",
      padding: theme.spacing(2.5),
      backgroundColor: "white",
      color: theme.palette.common.black,
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      zIndex: "1",
      [theme.breakpoints.up("sm")]: {
        borderRadius: "10px",
      },
      [theme.breakpoints.up(1350)]: {
        flexDirection: "row",
        padding: theme.spacing(4),
      },
      [theme.breakpoints.down(1120)]: {
        "&:after": {
          content: "''",
          position: "absolute",
          width: (props) => (props.xs === 6 ? "10px" : "80%"),
          height: (props) => (props.xs === 6 ? "80%" : "10px"),
          backgroundColor: theme.palette.secondary.main,
          top: (props) => (props.xs === 6 ? "20px" : "-10px"),
          left: (props) => (props.xs === 6 ? "-10px" : "75px"),
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: (props) => (props.xs === 6 ? "10px" : 0),
          borderTopRightRadius: (props) => (props.xs === 6 ? 0 : "10px"),
        },
      },
      [theme.breakpoints.up(1120)]: {
        "&:after": {
          content: "''",
          position: "absolute",
          width: "10px",
          height: "80%",
          backgroundColor: theme.palette.secondary.main,
          top: "20px",
          left: "-10px",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
        },
      },
      // ['@media (max-width:1500px)']: {
      //     padding: '20px 275px 20px 20px',
      // }
      // NOTE!: Temporary commented for swiss olympic games
      // '&:after': {
      //     content: "''",
      //     position: 'absolute',
      //     zIndex: -1,
      //     right: 0,
      //     top: 0,
      //     borderTopRightRadius: '10px',
      //     borderBottomRightRadius: '10px',
      //     width: '30%',
      //     height: '100%',
      //     background: `linear-gradient(to right, transparent, ${theme.palette.primary.var1})`,
      //     opacity: '0.4'
      // }
    },
    content: {
      display: "flex",
      height: "100%",
      padding: 0,
      width: "98%",
      flexDirection: "column",
      "& .session-title": {
        color: theme.palette.tertiary.var2,
        fontFamily: theme.typography.proximaExtraBold,
        minHeight: 30,
      },
      "& .description": {
        width: (props) => (props.xs === 6 ? "155px" : "auto"),
        maxHeight: "20px",
        overflow: "hidden",
        // display: '-webkit-box',
        // lineClamp: 5,
        // boxOrient: 'vertical',
        margin: theme.spacing(1, 0, 1, 0),
        // overflow: 'hidden',
        color: theme.palette.primary.main,
      },
    },
    cover: {
      position: (props) => (props.xs === 6 ? "absolute" : "relative"),
      left: (props) => (props.xs === 6 ? "30px" : "10px"),
      width: "100%",
      height: 170,
      padding: "25px 10px",
      display: "flex",
      justifyContent: "flex-end",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: 0,
      overflow: "hidden",
      [theme.breakpoints.up("sm")]: {
        width: (props) => (props.xs === 6 ? 240 : 330),
        minWidth: (props) => (props.xs === 6 ? 240 : 330),
        borderBottomLeftRadius: (props) => (props.xs === 6 ? "10px" : 0),
      },
      [theme.breakpoints.up(1120)]: {
        width: 240,
        minWidth: 240,
        height: 170,
        borderBottomLeftRadius: "10px",
        borderTopRightRadius: 0,
        borderTopLeftRadius: "10px",
        padding: "25px 10px",
      },
      [theme.breakpoints.down(1120)]: {
        top: (props) => (props.xs === 6 ? "40px" : 0),
      },
    },
    liveStreamTxt: {
      fontSize: "0.65rem",
      padding: theme.spacing(0.7),
      color: theme.palette.common.white,
      letterSpacing: "1px",
      backgroundColor: theme.palette.secondary.main,
      borderTopLeftRadius: "7px",
      borderBottomLeftRadius: "7px",
      [theme.breakpoints.down(1120)]: {
        writingMode: (props) => (props.xs === 6 ? "initial" : "vertical-rl"),
        textOrientation: (props) => (props.xs === 6 ? "initial" : "upright"),
        letterSpacing: (props) => (props.xs === 6 ? "1px" : 0),
      },
    },
    speakersWrap: {
      display: "flex",
      alignItems: "center",
      marginTop: "auto",
      "& .speakers-inwrap": {
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
          flexDirection: (props) => (props.xs === 6 ? "column" : "row"),
        },
        "& .speakers-title": {
          marginBottom: (props) => (props.xs === 6 ? "5px" : "0px"),
          color: theme.palette.tertiary.var2,
          paddingBottom: theme.spacing(1),
          [theme.breakpoints.up("sm")]: {
            paddingBottom: 0,
          },
        },
      },
    },
    sessionTimeBox: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(1, 0, 0),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: theme.palette.common.white,
      borderRadius: "10px",
      [theme.breakpoints.up(1350)]: {
        width: theme.spacing(35),
        padding: theme.spacing(2.5, 0, 0, 0.5),
      },
      "& .session-time-title": {
        color: theme.palette.tertiary.var2,
        fontWeight: "bold",
        fontSize: "1em",
      },
      "& button": {
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.quaternary.var1,
        textTransform: "uppercase",
        color: theme.palette.quaternary.var3,
        "&:hover": {
          backgroundColor: theme.palette.quaternary.var2,
          color: theme.palette.quaternary.var4,
        },
        [theme.breakpoints.up(1350)]: {
          marginTop: 0,
        },
      },
      "& .live-sign": {
        padding: "5px 10px",
        borderRadius: "6px",
        color: "white",
        letterSpacing: "1px",
        backgroundColor: theme.palette.secondary.main,
      },
    },
    subtitleUtil: {
      opacity: "50%",
      display: "inline-flex",
      color: theme.palette.tertiary.var2,
      fontWeight: "bold",
      fontSize: "1.25em",
    },
    endTime: {
      opacity: "50%",
      display: "inline-flex",
    },
    startHour: {
      paddingRight: theme.spacing(1),
    },
    timeBlock: {
      marginBottom: 10,
    },
    label: {
      position: "absolute",
      [theme.breakpoints.down(1120)]: {
        top: (props) => (props.xs === 6 ? "50px" : "-145px"),
        left: (props) => (props.xs === 6 ? "-90px" : "160px"),
      },
      [theme.breakpoints.up(1120)]: {
        left: "-90px",
        top: "50px",
      },
    },
  })
);

/**
 * Session card component used for display of teaser session information
 * @returns {JSX.Element}
 * @author petar.todorovski
 * @author dame.gjorgjievski
 */
export default function SessionCard(props) {
  const { value } = useLanguage();
  const history = useHistory();
  const classes = styles(props);
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  const ts = DateUtil.fromUtc(new Date(props.session.timeStart)),
    te = DateUtil.fromUtc(new Date(props.session.timeEnd)),
    now = new Date();

  const navigate = (e) => {
    e.preventDefault();
    switch (props.session.type) {
      case SessionType.EXTERNAL:
        WindowUtil.open(value(props.session.url));
        break;
      default:
        history.push(
          Path.EVENT_SESSION.replace(":id", props.event.id).replace(
            ":sid",
            props.session.id
          )
        );
        break;
    }
  };

  const renderTypeLabel = (type) => {
    switch (type) {
      case SessionType.LIVE:
        return t("client.session.labels.livestream");
      case SessionType.ONSITE:
        return t("client.session.labels.onsite");
      case SessionType.BREAKOUT:
        return t("client.session.labels.breakout");
      case SessionType.NETWORKING:
        return t("client.session.labels.round-table");
      case SessionType.EXTERNAL:
        return t("client.session.labels.external");
      default:
        return t("client.session.labels.livestream");
    }
  };

  const onUserSelect = (user, event) => {
    UserService.viewProfile(dispatch, analytics, user);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card className={classes.root} elevation={0}>
          <CardMedia
            className={classes.cover}
            title="Session Image"
            image={
              props.session.image
                ? FileService.url(props.session.image.uri)
                : DEFAULT_SESSION_IMAGE
            }
          ></CardMedia>
          <div className={classes.details}>
            <Box className={classes.label}>
              <Typography
                variant="button"
                component="span"
                className={classes.liveStreamTxt}
              >
                {renderTypeLabel(props.session.type)}
              </Typography>
            </Box>
            <CardContent className={classes.content}>
              <Typography className="session-title" component="h5" variant="h5">
                {value(props.session.name)}
              </Typography>
              <Typography
                variant="body2"
                className="description"
                color="textSecondary"
                dangerouslySetInnerHTML={{
                  __html: value(props.session.description),
                }}
              ></Typography>

              {/* Session Speakers */}
              <div className={classes.speakersWrap}>
                {props.session.speakers.length > 0 && (
                  <Box display="flex" className="speakers-inwrap">
                    <Box
                      pr={2}
                      display="flex"
                      alignItems="center"
                      color={theme.palette.primary.main}
                    >
                      <Typography
                        variant="button"
                        component="span"
                        className="speakers-title"
                        style={{ fontSize: "1.125rem" }}
                      >
                        {t("client.event.labels.speakers")}
                      </Typography>
                    </Box>

                    <UserAvatarList
                      onSelect={onUserSelect}
                      max={props.xs === 6 ? 2 : 4}
                      users={props.session.speakers}
                    />
                  </Box>
                )}
              </div>
            </CardContent>
            <Box className={classes.sessionTimeBox}>
              <div className={classes.timeBlock}>
                <Typography className="session-time-title" variant="h6">
                  {ts < now
                    ? t("client.session.labels.started-at") + ":"
                    : t("client.session.labels.starts-at") + ":"}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={1}
                >
                  <Typography className={classes.startHour} variant="h3">
                    {DateUtil.getHours(ts)}:{DateUtil.getMinutes(ts)}
                  </Typography>
                  <SessionStatusFlag session={props.session} />
                </Box>
                <Box>
                  <Typography
                    className={classes.subtitleUtil}
                    variant="subtitle2"
                  >
                    {t("client.session.labels.until")}: &nbsp;
                  </Typography>
                  <Typography variant="h6" className={classes.endTime}>
                    {DateUtil.getHours(te)}:{DateUtil.getMinutes(te)}
                  </Typography>
                </Box>
              </div>
              <Button
                size="large"
                variant="contained"
                disableElevation
                fullWidth
                onClick={navigate}
              >
                {ts > now && te > now
                  ? t("client.session.actions.join")
                  : t("client.session.actions.play")}
              </Button>
            </Box>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}

SessionCard.propTypes = {
  session: PropTypes.object,
  event: PropTypes.object,
};
